<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="8" lg="10" md="10" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Clé de label par activité" />
          </div>

          <div class="d-flex justify-center">
            <WarningEditComponent />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Détail d'une clé de label</div>
              </v-row>
            </v-card-title>

            <!-- le label par défaut -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    Clé
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="La clé"
                    dense
                    v-model="key"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le label par défaut -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    Label par défaut
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le label par défaut"
                    dense
                    v-model="defaultLabel"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!hasChanged"
                @click="save"
                >
                <div class="capitalize">
                  enregistrer
                </div>
              </v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import WarningEditComponent from "@/components/ui/WarningEditComponent.vue";

import { ConfAppAvecIndicatorsService } from "@/service/conf/app_avec_indicators_service.js";

import { areTheSame } from "@/tools/string_tool.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "EditConfAvecKeysLabelsActivities",
  components: {
    Workflow,
    TitleAndReturnComponent,
    WarningEditComponent,
    AlertNotSavedModifsComponent,
  },

  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      service: null,

      /** l'identifiant à éditer */
      entityId: null,

      /**La donnée originale */
      source: null,
      /**la clé */
      key: null,
      /** le label par défaut */
      defaultLabel: null,
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        // Récupération du label
        let entity = await this.service.getById(
          this.entityId
        );
        this.source = JSON.parse(JSON.stringify(entity));

        this.init();
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**initialiser les données à partir de l'objet source */
    init() {
      this.key = this.source.key;
      this.defaultLabel = this.source.defaultLabel;
    },


    /** Sauvegarde d'un label */
    async save() {
      let success = true;

      // enregistrement de l'entité
      if (this.entityHasChanged) {
        try {
          let entity = JSON.parse(JSON.stringify(this.source));
          entity.key = this.key;
          entity.defaultLabel = this.defaultLabel;
          entity.labelsByActivity = this.source.labelsByActivity;

          await this.service.update(entity);
          this.source = JSON.parse(JSON.stringify(entity));
        } catch (error) {
          success = false;
          console.error(error);
          this.addErrorToSnackbar(
            "enregistrement de l'activité : " +
              (exceptions.toMessage(error) || "problème technique")
          );
        }
      }      

      if (success) {
        this.disableAlertQuit();
        this.$router.go(-1);
      }
    },

  },
  computed: {

    completed() {
      if (!this.key) return false;
      if (!this.defaultLabel) return false;

      return true;
    },

    hasChanged() {
      if (!this.source) return false;

      if (!this.completed) return false;

      let changed = false;     

      if (this.entityHasChanged) {
        changed = true;
      }


      return changed;
    },
    /**retourne true si l'activité est modifiée (ne concerne pas les sous-activités: voir subActivitiesHaveChanged)  */
    entityHasChanged() {
      let changed = false;

      if (!areTheSame(this.source.key, this.key)) {
        changed = true;
      }

      if (!areTheSame(this.source.defaultLabel, this.defaultLabel)) {
        changed = true;
      }
      
      return changed;
    },

  },
  mounted() {
    //instanciation des services d'accès aux APIs
    this.service = new ConfAppAvecIndicatorsService(this.$api.getAppAvecIndicatorsApi());

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.entityId = this.$route.params.id;
    }

    this.load();
  },
};
</script>

<style>
</style>